<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="secondary">
        <ion-button v-if="playerData.campaignList" id="campaign-switcher">
          <ion-icon v-if="playerData.campaignList.length > 1" slot="icon-only" :icon="dice"></ion-icon>
        </ion-button>
        <ion-popover trigger="campaign-switcher" :dismiss-on-select="true">
          <ion-content>
            <CampaignSwitcher
                :campaignList="playerData.campaignList"
                @selectDefaultCampaign="setDefaultCampaign"
            />
          </ion-content>
        </ion-popover>
        <ion-button @click="triggerLogout">
          <ion-icon slot="icon-only" :icon="logOutOutline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <InviteBanner />
  </ion-header>
</template>

<script>
import {defineComponent} from 'vue';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPopover,
  IonTitle,
  IonToolbar,
  IonIcon,
} from "@ionic/vue";
import {dice, logOutOutline} from 'ionicons/icons';
import CampaignSwitcher from "@/components/campaign/CampaignSwitcher";
import {mapActions, mapGetters} from "vuex";
import InviteBanner from "@/components/header/InviteBanner";

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Toolbar',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonPopover,
    IonContent,
    IonIcon,
    CampaignSwitcher,
    InviteBanner,
  },
  props: {
    title: {
      type: String,
      default: 'Cat Herder',
      required: false
    },
  },
  data() {
    return {
      dice,
      logOutOutline,
      campaignList: [],
      selectedCampaign: 0,
    }
  },
  computed: {
    ...mapGetters("player", {
      playerData: "getPlayerData"
    }),
  },
  methods: {
    ...mapActions("player", {
          setPlayerData: "setPlayerData",
        }
    ),
    ...mapActions("auth", {
          logout: "logout"
        }
    ),
    triggerLogout() {
      this.logout()
      this.$router.push("/account/login")
    },
    async setDefaultCampaign({campaignId}) {
      this.selectedCampaign = campaignId
    },
  }
});
</script>

<style scoped lang="scss">

</style>
