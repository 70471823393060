import {createStore} from 'vuex';
import AuthModule from './auth';
import PlayerModule from './player';

const store = createStore({
    modules: {
        auth: AuthModule,
        player: PlayerModule,
    }
});

export default store;