<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Cat Herder</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="vertical-center">
        <ion-card class="login-card">
          <ion-card-header color="primary">
            <ion-card-title>Forgotten Password</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-item>
              <ion-label position="stacked">Email</ion-label>
              <ion-input v-model="userInfo.email"></ion-input>
            </ion-item>
            <ion-button expand="full" @click="requestLink()" class="ion-margin-top">
              Submit
            </ion-button>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonInput,
  IonButton, IonContent, IonPage, IonHeader, IonToolbar, IonTitle, toastController
} from "@ionic/vue";

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonTitle,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonInput,
    IonButton
  },
  data() {
    return {
      userInfo: {
        email: "",
      },
    };
  },
  methods: {
    async requestLink() {
      this.axios.post('password-reset/',
          this.userInfo
      ).then(({data}) => {
        if (data.status === "OK") {
          this.openToast()
        }
      })
    },
    async openToast() {
      const toast = await toastController
          .create({
            position: 'bottom',
            color: 'success',
            message: 'Email confirmation sent, check your email!',
            duration: 2000
          })
      return toast.present();
    },
  },
};
</script>

<style scoped lang="scss">
.vertical-center {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.login-card {
  width: 100%;
}

@media(min-width: 768px) {
  .login-card {
    width: 500px;
  }
}
</style>