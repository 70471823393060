<template>
  <ion-item v-if="campaignList.length > 1">
    <ion-list lines="full">
      <ion-item
          v-for="campaign in campaignList"
          :key="campaign.id"
          @click="selectDefaultCampaign(campaign.id)"
          class="selectable-campaign"
      >
        <ion-label>{{ campaign.name }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-item>
</template>

<script>
import {defineComponent} from 'vue';
import {IonItem, IonLabel, IonList} from "@ionic/vue";
import {mapActions, mapGetters} from "vuex";
import jwtInterceptor from "@/shared/jwtInterceptor";

export default defineComponent({
  name: 'CampaignSwitcher',
  components: {IonItem, IonList, IonLabel},
  props: {
    campaignList: Array,
  },
  computed: {
    ...mapGetters("player", {
      playerData: "getPlayerData"
    }),
  },
  methods: {
    ...mapActions("player", {
          setDefaultCampaign: "setDefaultCampaign"
        }
    ),
    selectDefaultCampaign(campaignId) {
      jwtInterceptor.post(`auth/player/${this.playerData.id}/default_campaign/`, {
        default_campaign: campaignId
      })
      this.setDefaultCampaign(campaignId)
      this.$emit("selectDefaultCampaign", {campaignId})
    }
  }
});
</script>

<style scoped lang="scss">
:host {
  ion-item {
    &:hover {
      --background-hover: red
    }
  }
}
</style>
