<template>
    <ion-toolbar class="sort-order-selector">
      <ion-segment @ionChange="setSortOrder($event)" :value="playerData.sortOrder">
        <ion-segment-button value="votes">
          <ion-label>Vote Order</ion-label>
        </ion-segment-button>
        <ion-segment-button value="dates">
          <ion-label>Date Order</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>
  <div v-if="!isLoading">
    <div v-if="potentialDates.length !== 0">
      <ion-grid>
        <ion-row>
          <ion-col v-for="(dateData, index) in potentialDates" :key="dateData" size="12" size-xl class="dates-list">
            <OthersDateCard :dateData="dateData" @click="setModalOpen(true, index)"/>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div v-else class="ion-margin-top">
      <ion-card>
        <ion-card-content>
          <ion-grid>
            <ion-row class="ion-justify-content-center ion-align-items-center">
              <ion-col size="3">
                <ion-icon :icon="arrowBackOutline" size="large"></ion-icon>
              </ion-col>
              <ion-col size="9">
                <h2>
                  Select some dates and let others know when you are available!
                </h2>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </div>
    <OtherPlayerDataModal :date-data="selectedDateData" :modal-is-open="modalIsOpen" @closeModal="setModalClosed" />
  </div>
  <div v-else >
    <ion-grid>
      <ion-row>
        <ion-col class="ion-align-items-center ion-text-center">
          <VueSpinner size="60" color="red"/>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid, IonIcon,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonToolbar
} from "@ionic/vue";
import OthersDateCard from "@/components/datesFromOthers/OthersDateCard.vue";
import jwtInterceptor from "@/shared/jwtInterceptor";
import {mapGetters} from "vuex";
import OtherPlayerDataModal from "@/components/datesFromOthers/OtherPlayerDataModal";
import {VueSpinner} from "vue3-spinners";
import {arrowBackOutline} from "ionicons/icons";

export default defineComponent({
  name: 'OthersDatesList',
  components: {
    OtherPlayerDataModal,
    IonRow,
    IonCol,
    IonGrid,
    OthersDateCard,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonToolbar,
    IonCard,
    IonCardContent,
    IonIcon,
    VueSpinner,
  },
  props: {
    name: String,
    selectedCampaignId: {
      type: Number
    },
    selectedSortOrder: {
      type: String
    }
  },
  data() {
    return {
      isLoading: true,
      potentialDates: [],
      modalIsOpen: false,
      selectedDateData: {},
      arrowBackOutline,
    }
  },
  computed: {
    ...mapGetters("player", {
      campaignId: "getCampaignId",
      playerData: "getPlayerData"
    }),
  },
  created() {
    this.emitter.on('fetch-dates', () => {
      this.fetchPotentialDates()
    })
  },
  mounted() {
    this.fetchPotentialDates()
  },
  watch: {
    campaignId: function () {
      this.fetchPotentialDates()
    }
  },
  methods: {
    async fetchPotentialDates() {
      const {data} = await jwtInterceptor.get(`events/${this.campaignId}/availability/`)
      for (let i in data) {
        data[i].date = new Date(data[i].date)
      }
      this.potentialDates = data
      this.isLoading = false
    },
    setModalOpen(isOpen, dataId) {
      this.selectedDateData = this.potentialDates[dataId]
      this.modalIsOpen = isOpen;
    },
    setModalClosed() {
      this.modalIsOpen = false
      this.selectedDateData = []
    },
    async setSortOrder({detail}) {
      this.isLoading = true
      const resp = await jwtInterceptor.post(`auth/player/${this.playerData.id}/sort_order/`, {
        sort_order: detail.value
      })
      this.fetchPotentialDates()
    }
  }
});
</script>

<style scoped lang="scss">
.sort-order-selector {
  margin-top: 0.5rem;
}
</style>
