import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '@/store'
import axios from 'axios'
import VueAxios from 'vue-axios'

import {IonicVue} from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import mitt from "mitt";

require('vue-ionicons/ionicons.css')

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API
})

const emitter = mitt()

const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(VueAxios, axios)
    .use(store)

app.provide('axios', app.config.globalProperties.axios = axiosInstance)  // provide 'axios')
app.config.globalProperties.emitter = emitter

router.isReady().then(() => {
    app.mount('#app');
});