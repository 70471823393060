<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Cat Herder</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="vertical-center">
        <ion-card class="login-card">
          <ion-card-header color="primary">
            <ion-card-title>Create a new herd</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p class="ion-padding">
              Your heard is your group, the people you play with.<br/>
              Once you have created your herd and setup your first campaign you'll be able to invite your friends.
            </p>
            <ion-item :class="{'ion-invalid' : formErrors['name'].length > 0}">
              <ion-label position="stacked">Name</ion-label>
              <ion-input v-model="herdName" @IonFocus="clearErrors('name')"></ion-input>
              <ion-note v-for="error in formErrors['name']" :key="error" slot="error">
                {{ error }}
              </ion-note>
            </ion-item>
            <ion-button @click="createNew()" expand="full" class="ion-margin-top" :disabled="!submitEnabled">
              Continue
            </ion-button>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonInput,
  IonButton, IonContent, IonPage, IonHeader, IonToolbar, IonTitle, IonNote
} from "@ionic/vue";
import jwtInterceptor from "@/shared/jwtInterceptor";
import {mapActions} from "vuex";

export default {
  name: "RegisterHerdPage",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonTitle,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonNote
  },
  data() {
    return {
      herdName: "",
      formErrors: {
        name: ""
      }
    };
  },
  computed: {
    submitEnabled() {
      return this.herdName !== "" && this.formErrors.name.length === 0
    }
  },
  methods: {
    ...mapActions("player", {
      setHerdId: "setHerdId"
    }),
    async createNew() {
      jwtInterceptor.post('groups/herds/',
          {name: this.herdName}
      ).then((response) => {
        this.setHerdId(response.data.id)
        if (response.statusText === "Created") {
          this.$router.push("/account/register/campaign/")
        }
      }).catch(({response}) => {
        for (let error in response.data) {
          this.formErrors[error] = response.data[error]
        }
      })
    },
    clearErrors(field) {
      this.formErrors[field] = []
      this.herdName = ""
    }
  },
};
</script>

<style scoped lang="scss">
.vertical-center {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.login-card {
  width: 100%;
}

.forgot-button-container {
  display: flex;
  justify-content: center;
}

@media(min-width: 768px) {
  .login-card {
    width: 500px;
  }
}
</style>
