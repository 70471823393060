<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Cat Herder</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="vertical-center">
        <ion-card class="login-card">
          <ion-card-header color="primary">
            <ion-card-title>Password Reset</ion-card-title>
          </ion-card-header>
          <ion-card-content v-if="!validToken">
            <h1>Sorry!</h1>
            <p>Looks like that token is invalid</p>
          </ion-card-content>
          <ion-card-content v-else>
            <ion-item>
              <ion-label position="stacked">New Password</ion-label>
              <ion-input type="password" v-model="userInfo.password" placeholder="Enter here"></ion-input>
            </ion-item>
            <ion-button expand="full" @click="resetPassword()" class="ion-margin-top">Reset</ion-button>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonInput,
  IonButton, IonContent, IonPage, IonHeader, IonToolbar, IonTitle, toastController
} from "@ionic/vue";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonTitle,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonInput,
    IonButton
  },
  data() {
    return {
      userInfo: {
        token: this.$route.params.token,
        password: "",
      },
      validToken: false
    };
  },
  mounted() {
    this.validateToken()
  },
  computed: {
    ...mapGetters("auth", {
      loginStatus: "getLoginStatus",
    }),
  },
  methods: {
    ...mapActions("auth", {
      loginUser: "loginUser",
    }),
    validateToken() {
      this.axios.post('password-reset/validate_token/', {
        "token": this.userInfo.token
      }).then(({data}) => {
        if (data.status === "OK") {
          this.validToken = true
        }
      })
    },
    async resetPassword() {
      this.axios.post('password-reset/confirm/',
          this.userInfo
      ).then(({data}) => {
        if (data.status === "OK") {
          this.openToast()
          this.$router.push("/account/login");
        }
      })
    },
    async openToast() {
      const toast = await toastController
          .create({
            position: 'bottom',
            color: 'success',
            message: 'Password reset!',
            duration: 2000
          })
      return toast.present();
    },
  },
};
</script>

<style scoped lang="scss">
.vertical-center {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.login-card {
  width: 100%;
}

@media(min-width: 768px) {
  .login-card {
    width: 500px;
  }
}
</style>