<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Cat Herder</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="!isLoading" :fullscreen="true">
      <div class="vertical-center">
        <ion-card class="login-card">
          <ion-card-header color="primary">
            <ion-card-title>Log In</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-item>
              <ion-label position="stacked">Email</ion-label>
              <ion-input v-model="userInfo.email" autofocus></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">Password</ion-label>
              <ion-input type="password" v-model="userInfo.password"></ion-input>
            </ion-item>
            <ion-button @click="login()" expand="full" class="ion-margin-top">
              Login
            </ion-button>
            <div class="forgot-button-container">
              <ion-button router-link="/account/forgot" fill="clear" size="small" color="medium">
                Forgot your password?
              </ion-button>
            </div>
          </ion-card-content>
        </ion-card>
        <p class="ion-text-center">
          Don't have a Cat Herder account? <router-link to="/account/register">Sign up</router-link>
        </p>
      </div>
    </ion-content>
    <ion-content v-else :fullscreen="true">
      <div class="vertical-center ion-text-center loading">
        <VueSpinner size="60" color="red" /><br/>
        <p>Spinning up the server...</p>
      </div>
    </ion-content>
    <ion-modal :is-open="showCampaignSwitcher">
      <ion-content>
        <ion-card class="selectable-card ion-no-padding">
          <ion-card-header>
            <ion-card-title>Set your default campaign</ion-card-title>
          </ion-card-header>
          <ion-card-content class="selectable-card-content">
            <CampaignSwitcher @selectDefaultCampaign="selectDefaultCampaign($event)"/>
          </ion-card-content>
        </ion-card>
      </ion-content>
    </ion-modal>
  </ion-page>
</template>
<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonModal,
  isPlatform,
  toastController,
} from "@ionic/vue";
import {mapGetters, mapActions} from "vuex";
import CampaignSwitcher from "@/components/campaign/CampaignSwitcher";
import {VueSpinner} from 'vue3-spinners';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonTitle,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonModal,
    CampaignSwitcher,
    VueSpinner,
  },
  data() {
    return {
      userInfo: {
        email: "",
        password: "",
      },
      showCampaignSwitcher: false,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters("auth", {
      loginStatus: "getLoginStatus",
    }),
    ...mapGetters("player", {
      playerData: "getPlayerData"
    }),
  },
  mounted() {
    this.axios.get(process.env.VUE_APP_ROOT_API).then(() => {
      this.isLoading = false
    })
  },
  methods: {
    ...mapActions("auth", {
      loginUser: "loginUser",
    }),
    ...mapActions("player", {
          setPlayerData: "setPlayerData"
        }
    ),
    async login() {
      if (this.userInfo.email && this.userInfo.password) {
        await this.loginUser(this.userInfo);
        if (this.loginStatus === "success") {
          await this.setPlayerData()
          if (isPlatform("mobile")) {
            this.$router.push("/m/mine");
          } else {
            this.$router.push("/dates");
          }
        } else {
          this.openToast()
        }
      }
    },
    async openToast() {
      const toast = await toastController
          .create({
            position: 'bottom',
            color: 'danger',
            message: 'Login failed, check your email and password',
            duration: 2000
          })
      return toast.present();
    },
  },
};
</script>

<style scoped lang="scss">
.vertical-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  &.loading {
    flex-direction: column;
  }
}

.login-card {
  width: 100%;
}

.forgot-button-container {
  display: flex;
  justify-content: center;
}

@media(min-width: 768px) {
  .login-card {
    width: 500px;
  }
}
</style>
