<template>
  <div v-if="playerCount === 1 && inviteCode" class="invite-toolbar ion-text-center ion-padding">
    Its a bit quiet in here, invite your friends using this link:
    <strong class="url">{{ inviteUrl }}</strong>
    <div id="click-trigger" class="copy-icon" @click="copyInviteCode()">
      <ion-icon slot="icon-only" :icon="copyOutline"></ion-icon>
    </div>
    <ion-popover trigger="click-trigger" trigger-action="click" side="right" style="--offset-y: -15px;">
      <ion-content class="ion-padding">Copied!</ion-content>
    </ion-popover>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {
  IonContent,
  IonPopover,
  IonIcon,
} from "@ionic/vue";
import { copyOutline} from 'ionicons/icons';
import {mapActions, mapGetters} from "vuex";
import jwtInterceptor from "@/shared/jwtInterceptor";

export default defineComponent({
  name: 'InviteBanner',
  components: {
    IonPopover,
    IonContent,
    IonIcon,
  },
  props: {
    title: {
      type: String,
      default: 'Cat Herder',
      required: false
    },
  },
  data() {
    return {
      copyOutline,
      playerCount: 99,
      inviteCode: "",
      inviteUrl: "",
    }
  },
  computed: {
    ...mapGetters("player", {
      playerData: "getPlayerData"
    }),
  },
  mounted() {
    console.log(process.env.VUE_APP_FRONTEND_URL)
    setTimeout(() => {
      this.fetchHerdData()
    }, 3000)
  },
  methods: {
    ...mapActions("player", {
          setPlayerData: "setPlayerData",
        }
    ),
    ...mapActions("auth", {
          logout: "logout"
        }
    ),
    triggerLogout() {
      this.logout()
      this.$router.push("/account/login")
    },
    async setDefaultCampaign({campaignId}) {
      this.selectedCampaign = campaignId
    },
    async fetchHerdData() {
      const {data} = await jwtInterceptor.get(`groups/herds/${this.playerData.defaultHerd}/`)
      console.log(data)
      this.playerCount = data.players.length
      this.inviteCode = data.invite_code
      this.inviteUrl = process.env.VUE_APP_FRONTEND_URL + "invite/" + this.inviteCode
      console.log(this.playerCount)
    },
    copyInviteCode() {
      navigator.clipboard.writeText(this.inviteUrl)
          .then(() => {
            console.log("copied!")
          })
          .catch(err => {
            console.error('Error in copying text: ', err);
          });
    }
  }
});
</script>

<style scoped lang="scss">
.invite-toolbar {
  align-items: center;
  background: var(--ion-color-warning);
  color: var(--ion-color-warning-contrast);
  display: flex;

  .url {
    margin-left: 0.5rem;
  }

  .copy-icon {
    align-items: center;
    background: var(--ion-color-warning-shade);
    border-radius: 15px;
    display: flex;
    height: 30px;
    justify-content: center;
    margin-left: 0.5rem;
    width: 30px;
  }

  ion-popover {
    --width: 80px;
  }
}
</style>

