<template>
  <ion-card color="light" class="date-select-card">
    <ion-card-header :class="getPanelColour" class="card-header">
      <ion-grid class="ion-no-padding">
        <ion-row class="ion-justify-content-between">
          <ion-col>
            {{ dateObj.dayOfWeek }} {{ dateObj.prefixedDate }} {{ dateObj.month }}
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-header>
    <ion-card-content class="card-content">
      <div class="votes-type">
        <span class="type"><checkmark-circle class="yes"/>Yes</span>
        <span class="votes">{{ dateData.yes_votes }} votes</span>
      </div>
      <div class="votes-type">
        <span class="type"> <alert-circle class="maybe"/>Maybe</span>
        <span class="votes">{{ dateData.maybe_votes }} votes</span>
      </div>
      <div class="votes-type">
        <span class="type"> <close-circle class="no"/>No</span>
        <span class="votes">{{ dateData.no_votes }} votes</span>
      </div>
      <p v-if="dateData.yet_to_vote_players.length > 0">
        Yet to vote:
        <ion-chip v-for="player in dateData.yet_to_vote_players" :key="player">
          {{ player }}
        </ion-chip>
      </p>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {defineComponent} from 'vue';
import {IonCard, IonCardContent, IonCardHeader, IonChip, IonCol, IonGrid, IonRow} from "@ionic/vue";
import {getFormattedDateObject} from "@/shared/dateFormat";
import CheckmarkCircle from 'vue-ionicons/dist/md-checkmark-circle.vue'
import CloseCircle from 'vue-ionicons/dist/md-close-circle.vue'
import AlertCircle from 'vue-ionicons/dist/md-alert.vue'

export default defineComponent({
  name: 'SelectedDateCard',
  components: {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonGrid,
    IonRow,
    IonCol,
    IonChip,
    CheckmarkCircle,
    CloseCircle,
    AlertCircle
  },
  props: {
    dateData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      dateObj: getFormattedDateObject(this.dateData.date)
    }
  },
  computed: {
    getPanelColour() {
      if (this.dateData.yet_to_vote_players.length > 0) {
        return "waiting"
      }
      if (this.dateData.no_votes > 0) {
        return "fail"
      }
      if (this.dateData.maybe_votes > 0) {
        return "maybe"
      }
      if (this.dateData.yes_votes === this.dateData.voted) {
        return "success"
      }
      return "primary"
    }
  },
});
</script>

<style scoped lang="scss">
.date-select-card {
  min-width: 160px;
}
.card-header {
  &.waiting {
    background: none;
    color: var(--ion-text-color);
  }

  &.success {
    background: var(--ion-color-success);
    color: var(--ion-color-success-contrast);
  }

  &.maybe {
    background: var(--ion-color-warning);
    color: var(--ion-color-warning-contrast);
  }

  &.fail {
    background: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast);
  }
}

.card-content {
  cursor: pointer;
  margin-top: 1rem;

  .votes-type {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .type {
      align-items: center;
      display: flex;

      .ion {
        font-size: 1.5rem;
        margin-right: 5px;

        &.yes {
          color: var(--ion-color-success);
        }

        &.no {
          color: var(--ion-color-danger);
        }

        &.maybe {
          color: var(--ion-color-warning);
        }
      }
    }
  }

  .yet-to-vote {
    padding-left: 1rem;
  }
}

.availability-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .date-select-card {
    margin-bottom: 0.6rem;
    margin-top: 0.6rem;
  }
}
</style>
