import jwtInterceptor from "@/shared/jwtInterceptor";


const state = () => ({
    playerData: {
        id: null,
        displayName: null,
        email: null,
        defaultCampaign: null,
        defaultHerd: null,
        campaignList: [],
        herdId: null,
    }
});

const getters = {
    getPlayerId(state) {
        return state.playerData.id;
    },
    getPlayerData(state) {
        return state.playerData
    },
    getCampaignId(state) {
        return state.playerData.defaultCampaign
    },
    getHerdId(state) {
        return state.playerData.herdId
    }
};

const actions = {
    async setPlayerData({commit}, payload) {
        try {
            const response = await jwtInterceptor.get("auth/player/current/", payload);
            if (response.status === 200 || response.status === 201) {
                commit("savePlayerData", {
                    id: response.data["id"],
                    displayName: response.data["player_name"],
                    email: response.data["email"],
                    defaultCampaign: response.data["default_campaign"],
                    defaultHerd: response.data["default_herd"],
                    campaignList: response.data["campaigns"],
                    sortOrder: response.data["sort_order"]
                });
            } else {
                commit("clearPlayerData");
            }
        } catch (e) {
            commit("clearPlayerData");
        }
    },
    setDefaultCampaign({commit}, campaignId) {
        commit("setDefaultCampaign", campaignId)
    },
    setHerdId({commit}, herdId) {
        commit("setHerdId", herdId)
    }
};

const mutations = {
    savePlayerData(state, payload) {
        state.playerData = payload;
    },
    setDefaultCampaign(state, payload) {
        state.playerData.defaultCampaign = payload
    },
    setHerdId(state, payload) {
        state.playerData.herdId = payload
    },
    clearPlayerData(state) {
        state.playerData = {
            id: "",
            display_name: "",
            email: "",
            herdId: "",
            defaultCampaign: ""
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
