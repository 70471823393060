import {createRouter, createWebHistory} from "@ionic/vue-router";
import LoginPage from "@/pages/account/login/LoginPage.vue";
import DateSelectPageDesktop from "@/pages/dateSelectPage/DateSelectPageDesktop.vue";
import ResetPasswordPage from "@/pages/account/login/ResetPasswordPage.vue"
import ForgottenPasswordPage from "@/pages/account/login/ForgottenPasswordPage.vue"
import CreateCampaignPage from "@/pages/account/herd/CampaignDetailPage.vue"
import ListCampaignsPage from "@/pages/account/herd/ListCampaignsPage.vue"
import GitHubConvertPage from "@/pages/other/GitHubConvert.vue"
import YotoPage from '@/pages/other/YotoPage.vue';
import MobileLayout from "@/layouts/MobileLayout.vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from "@/store"
import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import RegisterPlayerDetailsPage from "@/pages/account/register/RegisterPlayerDetailsPage.vue";
import RegisterHerdPage from "@/pages/account/register/RegisterHerdPage.vue";
import RegisterCampaignPage from "@/pages/account/register/RegisterCampaignPage.vue";
import RegisterCompletePage from '@/pages/account/register/RegisterCompletePage.vue';
import AcceptInviteCodePage from '@/pages/account/register/AcceptInviteCodePage.vue';
const routes = [
    {
        path: "/",
        redirect: "/account/login",
    },
    {
        path: "/account/login",
        component: LoginPage,
        name: "Login",
    },
    {
        path: "/account/forgot",
        component: ForgottenPasswordPage,
        name: "Forgot",
    },
    {
        path: "/account/reset/:token",
        component: ResetPasswordPage,
        name: "PasswordReset"
    },
    {
        path: "/account/register",
        component: RegisterPlayerDetailsPage,
    },
    {
        path: "/account/register/herd",
        component: RegisterHerdPage
    },
    {
        path: "/account/register/campaign",
        component: RegisterCampaignPage
    },
    {
        path: "/account/register/complete",
        component: RegisterCompletePage
    },
    {
        path: "/account/campaigns/",
        component: ListCampaignsPage,
        children: [
            {
                path: "create",
                component: CreateCampaignPage
            }
        ]
    },
    {
        path: "/invite/:code",
        component: AcceptInviteCodePage,
    },
    {
        path: "/dates",
        component: DateSelectPageDesktop,
    },
    {
        path: "/gh-convert",
        component: GitHubConvertPage,
        name: "GitHubConvert"
    },
    {
        path: "/yoto",
        component: YotoPage,
        name: "Yoto"
    },
    {
        path: '/m',
        component: MobileLayout,
        children: [
            {
                path: '',
                redirect: '/m/mine'
            },
            {
                path: 'mine',
                component: () => import('@/pages/MyAvailabilityPage.vue')
            },
            {
                path: 'theirs',
                component: () => import('@/pages/SelectedDatesPage.vue'),
            },
            {
                path: 'settings',
                component: () => import('@/pages/mobile/SettingsPage.vue'),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

function guard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext, authData: any) {
    const unguardedRoutes = [
        "/account/login",
        "/account/register",
        "/account/register/herd/",
        "/account/register/campaign/",
        "/account/forgot",
        "/account/reset",
        "PasswordReset",
        "GitHubConvert",
        "Yoto"
    ]
    if (authData && authData.userId > 0) {
        return next()
    } else {
        if (unguardedRoutes.includes(to.path) || unguardedRoutes.includes(<string>to.name) || to.path.includes("/invite/")) {
            return next()
        }
        return next({name: 'Login'})
    }
}

router.beforeEach((to, from, next) => {
    let authData = store.getters["auth/getAuthData"];
    if (authData.userId == 0) {
        store.dispatch("auth/loadStorageTokens").then(
            () => {
                authData = store.getters["auth/getAuthData"];
                return guard(to, from, next, authData);
            },
            (error: string) => {
                console.error(error)
                return guard(to, from, next, authData);
            }
        );
    } else {
        return guard(to, from, next, authData);
    }
});

export default router;
