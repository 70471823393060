export function getPrefixedDate(dateDay) {
    let daySuffix;
    switch (dateDay) {
        case 1:
        case 21:
        case 31:
            daySuffix = "st";
            break;
        case 2:
        case 22:
            daySuffix = "nd";
            break;
        case 3:
        case 23:
            daySuffix = "rd";
            break;
        default:
            daySuffix = "th";
            break;
    }
    return dateDay + daySuffix
}

export function getMonth(index) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return months[index]
}

export function getDayOfWeek(date) {
    return date.toLocaleString('default', {weekday: 'long'})
}

export function getFormattedDateObject(date) {
    if (typeof date === "object") {
        return {
            prefixedDate: getPrefixedDate(date.getDate()),
            month: getMonth(date.getMonth()),
            dayOfWeek: getDayOfWeek(date)
        }
    } else {
        return {
            prefixedDate: "",
            month: "",
            dayOfWeek: "",
        }
    }
}