<template>
  <div v-if="campaignId && isLoading === false" class="selectable-date-list">
    <ion-card v-for="spacer in spacerDays" :key="spacer" :class="cardSize" class="blank-card">
      &nbsp;
    </ion-card>
    <SelectableDate
        v-for="dateObj in dates"
        :key="dateObj.date_str"
        :date="dateObj.date_str"
        :availability="dateObj.availability"
        :selected-campaign-id="campaignId"
        :class="cardSize"
        class="selectable-date-card"
    />
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import SelectableDate from "@/components/selectableDates/SelectableDate.vue";
import {IonCard} from "@ionic/vue";
import jwtInterceptor from "@/shared/jwtInterceptor";
import {mapGetters} from "vuex";

export default defineComponent({
  name: 'SelectableDateList',
  components: {
    IonCard,
    SelectableDate,
  },
  props: {
    name: String,
  },
  data() {
    return {
      dates: {},
      playsDays: [],
      spacerDays: 0,
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters("auth", {
      loginStatus: "getLoginStatus",
    }),
    ...mapGetters("player", {
      campaignId: "getCampaignId",
    }),
    cardSize() {
      return `days-${this.playsDays.length}`
    }
  },
  mounted() {
    if (this.loginStatus === "success") {
      this.fetchPotentialDates()
    }
  },
  watch: {
    campaignId: function () {
      this.fetchPotentialDates()
    }
  },
  methods: {
    async fetchPotentialDates() {
      let fDates = []
      const resp = await jwtInterceptor.get(`events/${this.campaignId}/potential-dates/`)
      resp.data.dates.forEach(values => {
        fDates.push({
          "date_str": new Date(values.date_str),
          "availability": values.availability
        })
      })
      this.dates = fDates
      this.playsDays = resp.data.played_days
      this.isLoading = false
      const today = new Date().getDay()
      let spacerCount = 0
      if (resp.data.played_days.includes("sunday") && today >= 0) {
        spacerCount++
      }
      if (resp.data.played_days.includes("monday") && today >= 1) {
        spacerCount++
      }
      if (resp.data.played_days.includes("tuesday") && today >= 2) {
        spacerCount++
      }
      if (resp.data.played_days.includes("wednesday") && today >= 3) {
        spacerCount++
      }
      if (resp.data.played_days.includes("thursday") && today >= 4) {
        spacerCount++
      }
      if (resp.data.played_days.includes("friday") && today >= 5) {
        spacerCount++
      }
      if (resp.data.played_days.includes("saturday") && today >= 6) {
        spacerCount++
      }
      this.spacerDays = spacerCount
    },
  }
});
</script>

<style scoped lang="scss">
.selectable-date-list {
  display: flex;
  flex-wrap: wrap;

  .days-1 {
    width: 20%;
  }

  .days-2 {
    width: 46%;
  }

  .days-3 {
    width: 30%;
  }

  .days-4 {
    width: 20%;
  }

  .days-5 {
    width: 17%;
  }

  .days-6 {
    width: 13%;
  }

  .days-7 {
    width: 11%;
  }
}

.blank-card {
  background: none;
  border: none;
  box-shadow: none;
  height: 5rem;
}

@media (max-width: 900px) {
  .selectable-date-list {
    .days-1 .days-2, .days-3, .days-4, .days-5, .days-6, .days-7 {
      width: 44%;
    }
  }

  .blank-card {
    display: none;
  }
}

@media (max-width: 600px) {
  .selectable-date-list {
    .days-1, .days-2, .days-3, .days-4, .days-5, .days-6, .days-7 {
      width: 100%;
    }

    .selectable-card {
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
  }
}
</style>
