<template>
  <ion-page>
    <Toolbar/>
    <ion-content :fullscreen="true">
      <ion-row v-if="selectedCampaign !== 0"
               class="ion-justify-content-center selectable-dates-container"
      >
        <ion-col size="6">
          <SelectableDateList/>
        </ion-col>
        <ion-col size="6">
          <OthersDatesList/>
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script>
import {defineComponent} from "vue";
import OthersDatesList from "@/components/datesFromOthers/OthersDatesList.vue";
import SelectableDateList from "@/components/selectableDates/SelectableDateList.vue";
import {
  IonCol,
  IonContent,
  IonPage,
  IonRow,
} from "@ionic/vue";
import Toolbar from "@/components/header/Toolbar";
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: 'DateSelectPageDesktop',
  components: {
    OthersDatesList,
    SelectableDateList,
    IonPage,
    IonContent,
    IonRow,
    IonCol,
    Toolbar,
  },
  data() {
    return {
      selectedSegment: "mine",
      selectedCampaign: 0,
    }
  },
  mounted() {
    this.asyncSetPlayerData()
  },
  computed: {
    ...mapGetters("player", {
      playerData: "getPlayerData"
    }),
  },
  methods: {
    ...mapActions("player", {
          setPlayerData: "setPlayerData"
        }
    ),
    async setDefaultCampaign({campaignId}) {
      this.selectedCampaign = campaignId
    },
    async asyncSetPlayerData() {
      await this.setPlayerData()
      this.selectedCampaign = this.playerData.default_campaign
    }
  },
});
</script>