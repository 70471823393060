<template>
  <div class="wrapper">
    <div class="content">
      <p>Todays choice is from</p>
      <h1>{{ selected }}</h1>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      selected: "",
    }
  },
  mounted() {
    this.calculateToday()
  },
  methods: {
    getNumberOfDays(start, end) {
      const oneDay = 1000 * 60 * 60 * 24;
      const diffInTime = end.getTime() - start.getTime();
      const diffInDays = Math.round(diffInTime / oneDay);
      return diffInDays;
    },
    calculateToday() {
      const start = new Date("01/01/2022")
      const today = new Date()

      const numberDays = this.getNumberOfDays(start, today)
      const mod = numberDays % 4
      if (mod === 0) {
        this.selected = "Jasper"
      } else if (mod === 1) {
        this.selected = "Mummy"
      } else if (mod === 2) {
        this.selected = "Elodie"
      } else if (mod === 3) {
        this.selected = "Daddy"
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.wrapper {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  text-align: center;

  h1 {
    font-size: 4rem;
  }
}
</style>
