<template>
  <ion-modal :is-open="isOpen" color="light" class="date-select-card">
    <ion-header>
      <ion-toolbar>
        <ion-title>
          {{ dateObj.dayOfWeek }} {{ dateObj.prefixedDate }} {{ dateObj.month }}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="this.closeModal">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div v-for="availability in dateData.availabilities" :key="availability.name" class="availability-item">
      {{ availability.player_name }}
      <ion-chip :color="getStatusIcon(availability.status)">
        {{ availability.status.toUpperCase() }}
      </ion-chip>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script>
import {defineComponent} from 'vue';
import {getFormattedDateObject} from "@/shared/dateFormat";
import {IonButton, IonButtons, IonChip, IonContent, IonHeader, IonModal, IonTitle, IonToolbar} from "@ionic/vue";

export default defineComponent({
  name: 'OtherPlayerDataModal',
  components: {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonChip,
  },
  props: {
    dateData: {
      type: Object,
      default() {
        return {
          date: "",
          yet_to_vote_players: [],
          no_votes: 0,
          yes_votes: 0,
          maybe_votes: 0,
          voted: 0
        }
      }
    },
    modalIsOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // dateObj: getFormattedDateObject(this.dateData.date)
      dateObj: {},
      isOpen: this.modalIsOpen,
    }
  },
  mounted() {
    this.isOpen = this.modalIsOpen
    this.dateObj = getFormattedDateObject(this.dateData.date)
  },
  watch: {
    modalIsOpen: function() {
      this.isOpen = this.modalIsOpen
      this.dateObj = getFormattedDateObject(this.dateData.date)
    }
  },
  methods: {
    getStatusIcon(status) {
      if (status === "yes") {
        return "success"
      } else if (status === "no") {
        return "danger"
      } else if (status === "maybe") {
        return "warning"
      }
    },
    closeModal(){
      this.isOpen = false;
      this.$emit("closeModal")
    },
  }
});
</script>

<style scoped lang="scss">
.date-select-card {
  min-width: 160px;
}
.card-header {
  &.waiting {
    background: none;
    color: var(--ion-text-color);
  }

  &.success {
    background: var(--ion-color-success);
    color: var(--ion-color-success-contrast);
  }

  &.maybe {
    background: var(--ion-color-warning);
    color: var(--ion-color-warning-contrast);
  }

  &.fail {
    background: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast);
  }
}

.card-content {
  margin-top: 1rem;

  .votes-type {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .type {
      align-items: center;
      display: flex;

      .ion {
        font-size: 1.5rem;
        margin-right: 5px;

        &.yes {
          color: var(--ion-color-success);
        }

        &.no {
          color: var(--ion-color-danger);
        }

        &.maybe {
          color: var(--ion-color-warning);
        }
      }
    }
  }

  .yet-to-vote {
    padding-left: 1rem;
  }
}

.availability-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
</style>
