<template>
  <ion-item class="ion-margin-vertical" :class="{'ion-invalid' : formErrors['name'].length > 0}">
    <ion-label position="stacked">Campaign Name</ion-label>
    <ion-input
        v-model="name"
        @IonFocus="clearErrors('name')"
    ></ion-input>
    <ion-note v-for="error in formErrors['name']" :key="error" slot="error">
      {{ error }}
    </ion-note>
  </ion-item>
  <ion-label class="label-clone ion-padding">
    When do you usually play?
  </ion-label>
  <ion-list lines="none">
    <ion-item v-for="day in days" :key="day.val">
      <ion-label class="day-label">{{ day.val }}</ion-label>
      <ion-checkbox
          slot="end"
          @update:modelValue="day.isChecked = $event"
          :modelValue="day.isChecked">
      </ion-checkbox>
    </ion-item>
  </ion-list>
  <ion-note v-if="!(daysSelected)" slot="error" class="error-msg ion-padding">
    Select some days to play
  </ion-note>
  <ion-item :class="{'ion-invalid' : !(validShowNextNumber)}">
    <ion-label class="next-dates-label">Show dates for the next</ion-label>
    <ion-input v-model="showNextData.number" placeholder="30" type="number"></ion-input>
    <ion-select placeholder="Select One" :value="showNextData.type"
                @ionChange="showNextData.type=$event.target.value">
      <ion-select-option value="days">Days</ion-select-option>
      <ion-select-option value="weeks">Weeks</ion-select-option>
      <ion-select-option value="months">Months</ion-select-option>
    </ion-select>
    <ion-note v-if="!(validShowNextNumber)" slot="error" class="error-msg ion-padding">
      Invalid number of {{ showNextData.type }}
    </ion-note>
  </ion-item>
  <ion-button
      v-if="Object.entries(existingCampaignData).length === 0"
      @click="createNew()"
      expand="full"
      class="ion-margin-top"
      :disabled="!submitEnabled">
    Continue
  </ion-button>
  <ion-button
      v-else
      @click="updateExisting()"
      expand="full"
      class="ion-margin-top"
      :disabled="!submitEnabled">
    Update Campaign
  </ion-button>
</template>
<script>
import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonCheckbox,
  IonList,
  IonSelectOption,
  IonSelect,
  IonNote, toastController
} from "@ionic/vue";
import jwtInterceptor from "@/shared/jwtInterceptor";

export default {
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonList,
    IonCheckbox,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonNote,
  },
  props: {
    existingCampaignData: {
      type: Object,
      default() {
        return {}
      }
    },
    nextUrl: {
      type: String,
      default: ''
    },
    herdId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      name: "",
      days: [
        {val: 'monday', isChecked: true},
        {val: 'tuesday', isChecked: false},
        {val: 'wednesday', isChecked: false},
        {val: 'thursday', isChecked: false},
        {val: 'friday', isChecked: false},
        {val: 'saturday', isChecked: false},
        {val: 'sunday', isChecked: false},
      ],
      showNextData: {
        number: "30",
        type: "days"
      },
      formErrors: {
        name: ""
      }
    };
  },
  computed: {
    submitEnabled() {
      return (
          this.name !== "" &&
          this.showNextData.number !== "" &&
          this.herdId !== "" &&
          this.daysSelected
      )
    },
    daysSelected() {
      let daysValid = false
      this.days.forEach((day) => {
        if (day.isChecked === true) {
          daysValid = true
        }
      })
      return daysValid
    },
    validShowNextNumber() {
      return !Number.isNaN(parseInt(this.showNextData.number))
    }
  },
  mounted() {
    if(this.herdId === null) {
      this.noHerdIdToast()
    }
  },
  methods: {
    async createNew() {
      const data = {
        name: this.name,
        display_value: this.showNextData.number,
        display_units: this.showNextData.type,
        herd: this.herdId,
      }
      this.days.forEach((day) => {
        data["plays_" + day.val] = day.isChecked
      })
      jwtInterceptor.post('groups/campaigns/',
          data
      ).then((response) => {
        if (response.statusText === "Created") {
          this.$router.push("/account/register/complete/")
        }
      }).catch(({response}) => {
        for (let error in response.data) {
          this.formErrors[error] = response.data[error]
        }
      })
    },
    async updateExisting() {
      // TODO
    },
    async noHerdIdToast() {
      const toast = await toastController
          .create({
            position: 'bottom',
            color: 'danger',
            message: 'Error getting your herd ID, you may need to go back and try again',
            duration: 2000
          })
      return toast.present();
    },
    clearErrors(field) {
      this.formErrors[field] = []
      if (field === "name") {this.name = ""}
    }
  },
};
</script>

<style scoped lang="scss">
.vertical-center {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.login-card {
  width: 100%;
}

.forgot-button-container {
  display: flex;
  justify-content: center;
}

.label-clone {
  color: var(--ion-item-color, var(--ion-text-color, #000));
  font-size: 16px;
}

.day-label {
  text-transform: capitalize;
}

.next-dates-label {
  width: 100%;
}

.error-msg {
  color: var(--ion-color-danger);
  font-size: 12px;
}

@media(min-width: 768px) {
  .login-card {
    width: 500px;
  }
}
</style>
