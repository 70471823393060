<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Cat Herder</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-card class="login-card">
        <ion-card-header color="primary">
          <ion-card-title>Convert to correct PR format</ion-card-title>
        </ion-card-header>
        <ion-item>
          <ion-label position="stacked">Original</ion-label>
          <IonInput v-model="branchString" autofocus/>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Converted</ion-label>
          <h1>{{convertedBranchString}}</h1>
        </ion-item>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonContent,
  IonPage,
  IonInput, IonHeader, IonToolbar, IonTitle, IonCard, IonCardHeader, IonCardTitle, IonLabel, IonItem
} from '@ionic/vue';

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonItem,
    IonLabel,
    IonInput
  },
  data() {
    return {
      branchString: "",
    }
  },
  computed: {
    convertedBranchString() {
      return this.branchString.toUpperCase().replaceAll(" ", "-")
    }
  },
});
</script>