
import {defineComponent} from "vue";
import {peopleOutline, personOutline, settingsOutline} from 'ionicons/icons';
import {IonIcon, IonLabel, IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs} from "@ionic/vue";
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: 'MobileLayout',
  components: {
    IonIcon,
    IonLabel,
    IonPage,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonRouterOutlet
  },
  data() {
    return {
      selectedSegment: "mine",
      peopleOutline,
      personOutline,
      settingsOutline,
    }
  },
  mounted() {
    this.asyncSetPlayerData()
  },
  computed: {
    ...mapGetters("player", {
      playerData: "getPlayerData"
    }),
  },
  methods: {
    ...mapActions("player", {
          setPlayerData: "setPlayerData"
        }
    ),
    async asyncSetPlayerData() {
      await this.setPlayerData()
    },
  }

});
