import {Storage} from '@capacitor/storage';

import {jwtDecrypt} from "@/shared/helper";
import jwtInterceptor from "@/shared/jwtInterceptor";


const state = () => ({
    authData: {
        token: "",
        refresh: "",
        tokenExp: "",
        userId: "",
        email: "",
    },
    loginStatus: "success",
});

const getters = {
    getLoginStatus(state) {
        return state.loginStatus;
    },
    getAuthData(state) {
        return state.authData;
    },
};

const actions = {
    async loginUser({commit}, payload) {
        try {
            const response = await jwtInterceptor.post("auth/login/", payload);
            if (response.status === 200 || response.status === 201) {
                await Storage.set({
                    key: "access",
                    value: response.data.access,
                });
                await Storage.set({
                    key: "refresh",
                    value: response.data.refresh,
                });

                commit("saveAuthToken", response.data);
                commit("saveLoginStatus", "success");
            } else {
                commit("saveLoginStatus", "failed");
            }
        } catch (e) {
            commit("saveLoginStatus", "failed");
        }
    },
    async loadStorageTokens({commit}) {
        const access = await Storage.get({key: "access"});
        const refresh = await Storage.get({key: "refresh"});
        if (access && refresh) {
            const tokenData = {
                access: access.value,
                refresh: refresh.value,
            };
            commit("saveAuthToken", tokenData);
        }
    },

    async saveTokensToStorage({commit}, payload) {
        await Storage.set({
            key: "access",
            value: payload.access,
        });
        await Storage.set({
            key: "refresh",
            value: payload.refresh,
        });
        commit("saveAuthToken", payload);
    },

    async logout({commit}) {
        await Storage.clear()
    },

    async verifyUser({commit}) {
        try {
            const response = await jwtInterceptor.post("auth/verify/", {email: state.authData.email});
            return response
        } catch (e) {
            return e
        }
    }
};

const mutations = {
    saveAuthToken(state, payload) {
        const jwtDecodeUserInfo = jwtDecrypt(payload.access);
        const newAuthData = {
            token: payload.access,
            refresh: payload.refresh,
            // tokenExp: jwtDecodeUserInfo.exp,
            userId: jwtDecodeUserInfo["user_id"],
            // email: jwtDecodeUserInfo.email,
        };
        state.authData = newAuthData;
    },
    saveLoginStatus(state, status) {
        state.loginStatus = status;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
