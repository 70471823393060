<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Cat Herder</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="vertical-center">
        <ion-card class="login-card">
          <ion-card-header color="primary">
            <ion-card-title>On-going campaigns</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-button router-link="/account/herd/campaign/create" fill="clear" size="small" color="medium">
              Create a new campaign
            </ion-button>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton, IonContent, IonPage, IonHeader, IonToolbar, IonTitle
} from "@ionic/vue";
import jwtInterceptor from "@/shared/jwtInterceptor";

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonTitle,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonButton
  },
  data() {
    return {
      campaignName: ""
    };
  },
  computed: {},
  methods: {
    async createNew() {
      jwtInterceptor.post('groups/herds/',
          {name: this.herdName}
      ).then((response) => {
        if (response.statusText === "Created") {
          this.$router.push("/account/register/campaigns/")
        }
      })
    }
  },
};
</script>

<style scoped lang="scss">
.vertical-center {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.login-card {
  width: 100%;
}

.forgot-button-container {
  display: flex;
  justify-content: center;
}

@media(min-width: 768px) {
  .login-card {
    width: 500px;
  }
}
</style>