import axios from "axios";
import store from "../store/index";

const jwtInterceptor = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API
});

jwtInterceptor.interceptors.request.use((config) => {
    const authData = store.getters["auth/getAuthData"];
    if (authData == null) {
        return config;
    }
    config.headers.common["Authorization"] = `Bearer ${authData.token}`;
    return config;
});

jwtInterceptor.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const authData = store.getters["auth/getAuthData"];
        if (error.response.status === 401 && authData.refresh !== "undefined") {
            const payload = {
                refresh: authData.refresh,
            };

            var response = await jwtInterceptor.post(
                "auth/login/refresh/",
                payload
            );
            await store.dispatch("auth/saveTokensToStorage", response.data);
            error.config.headers[
                "Authorization"
                ] = `Bearer ${response.data.access}`;
            return axios(error.config);
        } else {
            return Promise.reject(error);
        }
    }
);

export default jwtInterceptor;
