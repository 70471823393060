<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Cat Herder</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="vertical-center">
        <ion-icon slot="icon-only" :icon="rocketSharp" size="large"></ion-icon>
        <h2>Registration Complete!</h2>
        <p>Your account is now ready! Go ahead and login</p>
        <ion-button @click="login()">
          Login
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonContent, IonPage, IonHeader, IonToolbar, IonTitle, IonIcon, IonButton
} from "@ionic/vue";
import {rocketSharp} from 'ionicons/icons';


export default {
  name: 'RegisterCompletePage',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonTitle,
    IonIcon,
    IonButton
  },
  data() {
    return {
      rocketSharp,
    }
  },
  methods: {
    login() {
      this.$router.push("/account/login/")
    }
  }
};
</script>

<style scoped lang="scss">
.vertical-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.login-card {
  width: 100%;
}

.forgot-button-container {
  display: flex;
  justify-content: center;
}

@media(min-width: 768px) {
  .login-card {
    width: 500px;
  }
}
</style>
