<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Cat Herder</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div v-if="!loading && !inviteCodeError" class="vertical-center">
        <ion-card class="login-card">
          <ion-card-header color="primary">
            <ion-card-title>You have been invited to join {{ herdName }}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-item :class="{'ion-invalid' : formErrors['email'].length > 0}">
              <ion-label position="stacked">Email</ion-label>
              <ion-input
                  v-model="userInfo.email"
                  @IonFocus="clearErrors('email')"
                  :color="formErrors.email !== '' ? 'danger' : ''"
              />
              <ion-note v-for="error in formErrors['email']" :key="error" slot="error">
                {{ error }}
              </ion-note>
            </ion-item>
            <ion-item :class="{'ion-invalid' : formErrors['display_name'].length > 0}">
              <ion-label position="stacked">Display Name</ion-label>
              <ion-input v-model="userInfo.display_name" @IonFocus="clearErrors('display_name')"></ion-input>
              <ion-note v-for="error in formErrors['display_name']" :key="error" slot="error">
                {{ error }}
              </ion-note>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">Password</ion-label>
              <ion-input v-model="userInfo.password" @IonFocus="clearErrors('password')" type="password"></ion-input>
            </ion-item>
            <ion-item class="ion-invalid">
              <ion-label position="stacked">Double checking that password</ion-label>
              <ion-input v-model="userInfo.password2" @IonFocus="clearErrors('password')" type="password"></ion-input>
              <template v-if="passwordValidator">
                <ion-note slot="error" v-if="passwordMismatch">Passwords do not match</ion-note>
              </template>
              <template v-else>
                <ion-note v-for="(error, i) in formErrors['password']" :key="i" slot="error">
                  {{ error }}
                </ion-note>
              </template>
            </ion-item>
            <ion-button @click="registerPlayer()" expand="full" class="ion-margin-top" :disabled="!submitEnabled">
              Continue
            </ion-button>
          </ion-card-content>
        </ion-card>
      </div>
      <div v-else-if="inviteCodeError">
        There was an error with your invite code. Please double check it and try again.
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonInput,
  IonButton, IonContent, IonPage, IonHeader, IonToolbar, IonTitle, IonNote
} from "@ionic/vue";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonTitle,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonNote
  },
  data() {
    return {
      loading: true,
      inviteCodeError: false,
      userInfo: {
        email: "",
        display_name: "",
        password: "",
        password2: "",
        invite_code: "",
      },
      formErrors: {
        email: [],
        display_name: [],
        password: [],
        invite_code: [],
      },
    };
  },
  computed: {
    ...mapGetters("auth", {
      loginStatus: "getLoginStatus",
    }),
    submitEnabled() {
      return (
          this.userInfo.email !== "" &&
          this.userInfo.display_name !== "" &&
          this.userInfo.password !== "" &&
          this.userInfo.password2 !== "" &&
          !this.passwordMismatch
      )
    },
    passwordMismatch() {
      return this.userInfo.password2.length > 0 && this.userInfo.password !== this.userInfo.password2
    },
    passwordValidator() {
      return this.passwordMismatch && this.formErrors.password.length === 0
    }
  },
  mounted() {
    this.userInfo.invite_code = this.$route.params.code
    this.logout()
    this.getHerdName()
  },
  methods: {
    ...mapActions("auth", {
      loginUser: "loginUser",
      logout: "logout"
    }),
    async getHerdName() {
      this.axios.get('groups/herds/' + this.userInfo.invite_code + '/get_by_invite_code/')
      .then(({data}) => {
        console.log(data)
        this.herdName = data.name
        this.loading = false
      }).catch(() => {
        this.inviteCodeError = true
      })
    },
    async registerPlayer() {
      this.axios.post('auth/invite/',
          this.userInfo
      ).then((response) => {
        if (response.statusText === "Created") {
          const loginInfo = {
            email: this.userInfo.email,
            password: this.userInfo.password
          }
          this.loginUser(loginInfo);
          if (this.loginStatus === "success") {
            this.$router.push("/dates/")
          }
        } else {
          response.forEach((error) => {
            console.log(error)
          })
        }
      }).catch(({response}) => {
        for (let error in response.data) {
          this.formErrors[error] = response.data[error]
        }
      })
    },
    clearErrors(field) {
      this.formErrors[field] = []
    }
  },
};
</script>

<style scoped lang="scss">
.vertical-center {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.login-card {
  width: 100%;
}

.forgot-button-container {
  display: flex;
  justify-content: center;
}

@media(min-width: 768px) {
  .login-card {
    width: 500px;
  }
}
</style>
