<template>
  <ion-card @click="setState()" :class="selectedState" class="selectable-card ion-no-padding">
    <ion-card-content class="selectable-card-content">
      <div class="day-weekday">
        <h1 class="day">{{ dateObj.prefixedDate }}</h1>
        <h4 class="weekday">{{ dateObj.dayOfWeek }}</h4>
      </div>
      <h6 class="month">{{ dateObj.month }}</h6>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {defineComponent} from 'vue';
import {IonCard, IonCardContent} from "@ionic/vue";
import {getFormattedDateObject} from "@/shared/dateFormat";
import jwtInterceptor from "@/shared/jwtInterceptor";

export default defineComponent({
  name: 'SelectableDate',
  components: {IonCard, IonCardContent},
  props: {
    date: {
      type: Date,
      default: ''
    },
    availability: {
      type: String,
      default: "",
      required: false
    },
    selectedCampaignId: {
      type: Number
    },
  },
  data() {
    return {
      selectedState: this.availability,
      dateObj: getFormattedDateObject(this.date)
    }
  },
  methods: {
    setState() {
      if (this.selectedState === "" || this.selectedState === "null") {
        this.selectedState = "yes"
      } else if (this.selectedState === "yes") {
        this.selectedState = "no"
      } else if (this.selectedState === "no") {
        this.selectedState = "maybe"
      } else if (this.selectedState === "maybe") {
        this.selectedState = "null"
      }
      jwtInterceptor.post(`events/${this.selectedCampaignId}/availability/create/`, {
        "date": this.date.toISOString().split('T')[0],
        "status": this.selectedState,
        "campaign": this.selectedCampaignId,
      }).then((resp) => {
        this.emitter.emit('fetch-dates')
      })
    }
  }
});
</script>

<style scoped lang="scss">
.selectable-card {

  &.yes {
    background: var(--ion-color-success);
    color: var(--ion-color-success-contrast);
  }

  &.no {
    background: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast);
  }

  &.maybe {
    background: var(--ion-color-warning);
    color: var(--ion-color-warning-contrast);
  }

  .selectable-card-content {
    align-items: center;
    display: flex;
    height: 5rem;
    justify-content: space-between;
    position: relative;
  }

  .month {
    font-size: 2rem;
    font-weight: bold;
    opacity: 0.3;
  }
}
</style>
